import { Inject, Injectable } from '@angular/core';
import { __values } from 'tslib';
import { xml2json } from 'xml-js';

import { FileSubmission } from './filesubmission';
import { CompletedPart, StatusMessage } from './types';

import { FtapiConfig, FTAPI_CONFIG } from './config';
import { ApiInfo } from './apiinfo';

@Injectable({
  providedIn: "root"
})
export class FtapiService {
  constructor(
    @Inject(FTAPI_CONFIG) private ftapiConfig: FtapiConfig
    ) { }

  // Create a multipart upload, returning the uploadId string.
  createMultipartUpload(token: string, bucket: string, key: string): Promise<string> {
    let url = this.ftapiConfig.prefix + bucket + "/" + encodeURIComponent(key) + "?uploads";
    return fetch(
      url,
      {
        method: "POST",
        headers: { "Authorization": "Bearer " + token}
      }
    )
    .then(response => response.text())
    .then(xml => {
      let res = JSON.parse(xml2json(xml, {compact: true, spaces: 4}));
      let uploadId = res["InitiateMultipartUploadResult"]["UploadId"]["_text"];
      return uploadId;
    });
  }

  // Call CompleteMultipartUpload
  async completeMultipartUpload(token: string, bucket: string, key: string, uploadId: string, parts: Array<CompletedPart>): Promise<Response> {
    let url = this.ftapiConfig.prefix + bucket + "/" + encodeURIComponent(key) + "?uploadId=" + uploadId;

    const doc = document.implementation.createDocument("", "", null);
    const multi = doc.createElement("CompleteMultipartUpload");
    const ser = new XMLSerializer();
    for (const part of parts) {
      const partxml = doc.createElement("Part");
      const etag = doc.createElement("ETag");
      etag.innerHTML = part.ETag;
      const partnum = doc.createElement("PartNumber");
      partnum.innerHTML = part.PartNumber.toString();
      partxml.appendChild(etag);
      partxml.appendChild(partnum);
      multi.appendChild(partxml);
    }
    doc.appendChild(multi);
    let xml = ser.serializeToString(doc);

    return fetch(url, {
      method: "POST",
      headers: { "Authorization": "Bearer " + token},
      body: xml,
    });
  }

  getObjectVersions(token: string, bucket: string): Promise<any> {
    let url = this.ftapiConfig.prefix + bucket + "/?versions";
    let dest_network_prefix = bucket.slice(bucket.lastIndexOf('.')+1);
    let dest_network: string;
    if (dest_network_prefix === 'sc') {
      dest_network = 'OA';
    }
    if (dest_network_prefix === 'cf') {
      dest_network = 'DSNet';
    }
    if (dest_network_prefix === 'rt') {
      dest_network = 'TechNet';
    }
    return fetch(url, {
      method: "GET",
      headers: { "Authorization": "Bearer " + token}
    })
    .then(response => response.text())
    .then(result => {
      return this.parseListVersionsResult(result, dest_network);
    })
    .catch(error => {
      console.log("getObjectVersions error: ", error);
      return ["getting list of transferred files"];
    })
  }

  getObjectUrl(file: FileSubmission, token: string | null): string {
    if (token !== null) {
      return this.ftapiConfig.prefix + file.bucket + "/" + file.getObjectRef() + "&X-Dso-Credential=DSO-FT1%2F" + token;
    } else {
      return this.ftapiConfig.prefix + file.bucket + "/" + file.getObjectRef();
    }
  }

  putObject(token: string, bucket: string, key: string, file: File): Promise<Response> {
    let url = this.ftapiConfig.prefix + bucket + "/" + encodeURIComponent(key);
    return fetch(
      url,
      {
        method: "PUT",
        headers: { "Authorization": "Bearer " + token },
        body: file,
      }
    );
  }

  // Upload a part of a multipart upload, returning the ETag.
  async uploadPart(token: string, bucket: string, key: string, partNumber: number, uploadId: string, filechunk: Blob): Promise<string | null> {
    let url = this.ftapiConfig.prefix + bucket + "/" + encodeURIComponent(key) + "?partNumber=" + partNumber.toString() + "&uploadId=" + uploadId;

    let retryCount = 0;
    while (retryCount < 3) {
      try {
        retryCount++;
        const response = await fetch(
          url,
          {
            method: "PUT",
            headers: { "Authorization": "Bearer " + token},
            body: filechunk
          }
        );
        if (response.status === 200) {
          return response.headers.get("ETag");
        }
      } catch(e) {
        console.error('uploadPart error = ', e);
      }
    }
    return null;
  }

  async getApiInfo(): Promise<ApiInfo> {
    let url = this.ftapiConfig.prefix + 'api/info';
    let response = await fetch(url);
    let apiInfoJSON = await response.json();
    let apiInfo = new ApiInfo(
      apiInfoJSON["commit"],
      apiInfoJSON["version"],
    );
    return apiInfo;
  }

  async getStatus(): Promise<Array<StatusMessage>> {
    let statuses: Array<StatusMessage> = [];
    let url = this.ftapiConfig.prefix + "portal/status.json";
    let response = await fetch(url);
    if (response.status == 404) {
      return [];
    } else {
      let stat = await response.json();
      for (const status of stat["messages"]) {
        statuses.push(
          new StatusMessage(
            status['severity'],
            status['message']
          )
        )
      }
    }
    return statuses;
  }

  private parseListVersionsResult(xml: string, dest_network: string): Array<FileSubmission> {
    let json_doc = JSON.parse(xml2json(xml, {compact: true, spaces: 4}));
    let versions = json_doc['ListVersionsResult'];
    let bucket = versions["Name"]["_text"];
    try {
      if (!("Version" in versions)) {
        return [];
      }
  
      var contents = versions['Version'];
      if (contents.constructor !== Array) {
        contents = [contents];
      }
      let filesTransferred: Array<FileSubmission> = [];
      let id = 1;
      // TO-DO: will this information be in ListVersions eventually? Hardcoded for now
      let source_network = "TechNet";
      for (const version of contents) {
        let filename = version['Key']['_text'];
        filesTransferred.push(
          new FileSubmission(
            id,
            new Date(Date.parse(version['LastModified']['_text'])),
            source_network,
            dest_network,
            bucket,
            filename,
            version["VersionId"]["_text"],
            Number(version["Size"]["_text"]),
            version["StorageClass"]["_text"],
            "todownload",
          )
        )
        id++;
      }
      return filesTransferred;
    } catch {
      var error = json_doc["Error"]["Message"]["_text"];
      return [error];
    }
  }
}
