<dso-navbar title="File Transfer" homepage="https://singaporedso.sharepoint.com/" existing="https://tn-apps.dso.org.sg/FTP/">
    <dso-nav-item #navItem title="Transfer" href="#" state="active"></dso-nav-item>
    <dso-nav-item #navItem title="Retrieve" href="#" state="disabled" label="Coming soon"></dso-nav-item>
</dso-navbar>

<main class="col-12 col-md-9 d-block mx-auto">
    <router-outlet *ngIf="!isIframe"></router-outlet>
</main>

<footer>
    <div class="row mx-auto col-12 col-md-9">
        <hr/>
        <div class="col-8">
            <p>File Transfer {{ apiInfo.version }}</p>
            <p><a href="https://singaporedso.service-now.com/sp?id=sc_cat_item&sys_id=3f1dd0320a0a0b99000a53f7604a2ef9">Report an issue</a></p>
            <p><a href="https://singaporedso.service-now.com/sp?id=kb_article_view&table=kb_knowledge&sys_kb_id=7906409f04deb510c0e59e103471475c">How do I transfer a file from X to Y?</a></p>
        </div>
        <div class="col-4">
            <p style="text-align: right"><a href="https://techstack.dso.org.sg/docs/filetransfer/">API Documentation</a></p>
        </div>
    </div>
</footer>
